import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 713.000000 724.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,724.000000) scale(0.100000,-0.100000)">


<path d="M0 7220 c0 -20 7 -20 3565 -20 3558 0 3565 0 3565 20 0 20 -7 20
-3565 20 -3558 0 -3565 0 -3565 -20z"/>
<path d="M5157 6988 c-9 -5 -17 -13 -17 -18 0 -16 -43 -61 -72 -76 -35 -18
-106 -18 -193 1 -332 73 -483 90 -720 82 -304 -11 -502 -62 -700 -180 -38 -23
-72 -44 -75 -47 -3 -3 -21 -17 -40 -30 -19 -13 -37 -27 -40 -30 -3 -3 -31 -27
-63 -54 -66 -55 -186 -193 -235 -269 -92 -145 -147 -319 -167 -529 -6 -66 -7
-66 -46 -82 -22 -9 -45 -16 -51 -16 -14 0 -122 -63 -228 -134 -97 -64 -252
-219 -319 -319 -134 -200 -191 -391 -191 -642 0 -149 18 -267 56 -378 61 -181
147 -320 278 -453 79 -79 221 -194 240 -194 4 0 48 -20 99 -45 334 -165 879
-208 1385 -110 129 25 392 109 404 128 4 7 8 148 8 314 0 363 4 389 69 455 37
37 60 50 108 64 58 16 80 34 67 55 -3 5 -233 9 -548 9 -453 0 -545 -2 -555
-14 -16 -19 11 -46 47 -46 61 -1 152 -65 190 -134 25 -46 31 -497 7 -579 -20
-68 -62 -109 -137 -133 -71 -22 -326 -31 -411 -14 -99 19 -255 76 -311 113
-101 67 -189 171 -252 296 -46 93 -52 110 -79 240 -62 302 -59 580 9 887 22
99 58 200 76 214 4 3 32 44 62 91 88 136 212 232 372 286 78 27 89 28 281 28
186 0 206 -2 291 -27 105 -30 232 -91 319 -153 55 -39 146 -128 170 -166 6 -9
19 -29 29 -45 51 -77 66 -101 77 -127 10 -23 18 -27 43 -25 l31 3 0 335 0 335
-24 0 c-18 0 -30 -11 -47 -40 -36 -65 -64 -77 -164 -71 -47 2 -125 14 -172 25
-190 46 -377 82 -456 88 -50 4 -86 11 -93 20 -23 27 29 399 60 437 5 6 17 31
27 56 30 76 118 212 168 263 27 26 51 47 53 47 2 0 19 11 36 24 226 170 637
182 917 28 203 -112 319 -274 400 -562 5 -19 13 -25 36 -25 l29 0 3 421 c2
444 2 447 -41 422z"/>
<path d="M3079 5440 c-68 -12 -180 -51 -186 -66 -6 -15 53 -144 113 -245 106
-181 353 -387 566 -472 157 -62 321 -94 530 -104 270 -12 516 34 730 136 124
58 196 111 331 240 l77 74 0 99 c0 54 -4 98 -8 98 -4 0 -37 -30 -72 -66 -330
-337 -774 -441 -1162 -274 -47 21 -110 54 -140 74 -63 41 -161 133 -207 193
-38 50 -131 229 -131 252 0 20 -67 48 -146 61 -68 11 -228 11 -295 0z"/>
<path d="M1845 3119 c-157 -19 -278 -94 -358 -224 -70 -114 -96 -317 -62 -480
19 -89 30 -117 74 -184 108 -167 356 -234 635 -171 l98 22 22 -21 c18 -17 25
-19 38 -8 13 10 17 41 20 177 l3 165 -30 3 c-28 2 -32 -2 -72 -80 -49 -97
-109 -169 -168 -203 -46 -28 -63 -30 -117 -15 -55 16 -93 53 -126 123 -41 87
-52 166 -52 373 0 206 10 274 51 361 33 70 76 103 142 110 91 10 181 -66 262
-218 34 -66 51 -89 66 -89 30 0 36 34 28 160 -4 63 -8 132 -8 153 -1 46 -23
58 -54 29 -22 -20 -23 -20 -107 -2 -111 23 -199 29 -285 19z"/>
<path d="M5460 3120 c-133 -18 -229 -83 -279 -190 -22 -46 -26 -68 -25 -140 2
-186 86 -280 316 -351 217 -66 268 -104 268 -199 0 -102 -106 -171 -217 -141
-95 26 -226 145 -293 269 -20 35 -29 42 -53 42 l-30 0 8 -182 c8 -208 16 -228
68 -172 l24 25 69 -15 c246 -55 410 -31 518 77 56 56 80 104 96 189 14 78 2
176 -30 237 -42 83 -134 137 -330 193 -197 56 -257 118 -219 226 13 38 94 82
150 82 88 0 188 -74 271 -201 40 -61 57 -79 75 -79 l23 0 0 143 c0 78 -3 152
-6 165 -8 28 -31 28 -60 1 l-22 -20 -83 19 c-108 26 -170 31 -239 22z"/>
<path d="M2407 3114 c-13 -13 -7 -42 9 -48 9 -3 29 -6 45 -6 l29 0 0 -479 c0
-464 -1 -480 -19 -486 -11 -3 -31 -3 -45 1 -23 5 -26 3 -26 -19 0 -14 7 -28
16 -31 24 -9 457 -7 472 3 20 12 10 46 -13 47 -11 1 -28 2 -37 3 -17 1 -18 19
-18 231 l0 230 125 0 125 0 -2 -232 -3 -233 -38 2 c-36 3 -38 1 -35 -25 l3
-27 234 -3 c241 -2 261 0 261 38 0 14 -8 18 -34 18 -19 0 -39 3 -45 7 -8 4
-11 155 -11 480 l0 474 43 3 c37 3 42 6 42 28 l0 25 -245 0 -245 0 0 -25 c0
-22 5 -25 38 -28 l37 -3 0 -215 0 -214 -125 0 -125 0 0 214 0 215 38 3 c32 3
37 6 37 28 l0 25 -241 3 c-132 1 -243 -1 -247 -4z"/>
<path d="M3514 3106 c-11 -30 4 -45 48 -48 l43 -3 2 -465 c2 -271 -1 -471 -6
-479 -7 -10 -24 -14 -51 -13 -40 3 -41 2 -38 -25 l3 -28 250 0 250 0 3 27 c3
25 0 27 -40 30 l-43 3 -3 233 c-2 216 -1 232 15 233 44 3 87 -2 98 -11 22 -18
35 -95 35 -209 0 -134 13 -202 48 -243 42 -49 100 -70 201 -70 90 0 117 8 158
45 28 25 63 116 63 162 0 30 -3 34 -26 32 -23 -2 -29 -9 -39 -49 -11 -46 -30
-78 -45 -78 -18 0 -30 69 -30 169 -1 169 -16 206 -111 272 l-24 17 40 16 c90
38 135 117 135 240 0 73 -33 153 -77 184 -34 24 -118 49 -193 57 -30 4 -191 9
-357 12 -272 5 -303 4 -309 -11z m524 -57 c56 -19 67 -49 67 -184 0 -108 -2
-124 -24 -161 -27 -49 -51 -63 -106 -67 l-40 -2 -3 213 -2 212 38 0 c22 0 53
-5 70 -11z"/>
<path d="M4592 3093 c3 -24 8 -28 41 -31 l37 -3 0 -474 c0 -345 -3 -476 -11
-481 -7 -4 -25 -7 -41 -6 -26 1 -29 -2 -26 -26 l3 -27 232 -3 c225 -2 232 -2
243 18 15 28 5 40 -35 40 -21 0 -35 6 -39 16 -8 20 -8 908 0 928 4 9 18 16 34
16 32 0 53 21 44 44 -5 14 -34 16 -246 16 l-239 0 3 -27z"/>
<path d="M1378 1769 c-88 -11 -175 -45 -241 -93 -140 -102 -206 -272 -194
-501 7 -122 21 -177 68 -266 64 -119 194 -198 361 -219 94 -11 166 -4 307 29
86 21 99 20 143 -12 55 -40 58 -28 58 196 l0 204 31 7 c26 5 30 10 27 31 l-3
26 -225 0 -225 0 0 -28 c0 -26 3 -28 43 -31 l42 -3 0 -157 c0 -192 -3 -199
-87 -204 -57 -3 -59 -2 -105 42 -37 37 -51 59 -70 120 -21 70 -23 91 -23 320
0 261 5 303 52 391 31 58 92 99 148 99 88 0 191 -99 286 -275 11 -20 24 -30
40 -30 21 0 24 5 27 40 2 22 1 100 -3 173 -8 146 -16 163 -60 122 l-23 -22
-89 20 c-111 25 -200 32 -285 21z"/>
<path d="M3325 1770 c-285 -31 -438 -223 -439 -550 0 -289 120 -465 354 -520
68 -16 238 -16 305 -1 172 41 292 161 341 341 24 89 25 300 1 389 -20 74 -66
164 -107 209 -92 101 -267 152 -455 132z m145 -72 c82 -42 100 -126 100 -458
0 -294 -20 -412 -78 -464 -13 -11 -44 -21 -79 -25 -95 -11 -148 35 -171 145
-18 82 -25 377 -14 540 13 182 31 232 96 264 53 26 91 25 146 -2z"/>
<path d="M5321 1756 c-68 -180 -174 -466 -191 -516 -51 -149 -173 -476 -181
-484 -4 -5 -22 -9 -39 -8 -28 1 -31 -2 -28 -26 l3 -27 116 -3 c94 -2 118 0
129 13 22 27 5 45 -46 45 -55 0 -56 -11 7 168 l43 122 137 0 137 0 15 -42 c84
-235 86 -265 19 -255 -31 6 -33 4 -30 -21 l3 -27 661 -3 c520 -1 664 1 671 10
9 12 33 257 33 337 0 28 -3 31 -29 31 -27 0 -34 -8 -72 -80 -83 -155 -173
-230 -277 -230 l-52 0 2 473 3 472 73 3 c73 3 73 3 70 30 l-3 27 -280 0 -280
0 0 -25 c0 -22 5 -26 40 -30 l40 -5 3 -472 2 -473 -38 0 c-21 0 -42 -3 -46 -7
-14 -15 -59 -13 -76 2 -10 9 -21 29 -25 43 -3 15 -62 180 -131 367 -69 187
-143 390 -165 450 -68 187 -50 166 -136 164 -66 -2 -75 -4 -82 -23z m19 -514
c22 -67 38 -127 35 -132 -3 -6 -51 -10 -106 -10 -112 0 -113 2 -85 77 8 21 31
85 52 143 44 122 36 128 104 -78z"/>
<path d="M1985 1749 c-9 -27 2 -37 45 -41 l35 -3 3 -472 2 -473 -39 0 c-31 0
-41 -4 -46 -20 -16 -49 -11 -50 415 -50 338 0 399 2 403 14 10 25 31 341 25
358 -4 10 -15 14 -30 11 -17 -3 -35 -27 -68 -85 -86 -155 -175 -228 -278 -228
l-52 0 0 475 0 475 75 0 75 0 0 30 0 30 -279 0 c-275 0 -280 0 -286 -21z"/>
<path d="M3964 1755 c-12 -29 4 -45 45 -45 25 0 41 -5 45 -15 11 -30 7 -915
-5 -937 -9 -17 -18 -19 -50 -15 -36 5 -39 3 -39 -19 0 -17 7 -25 26 -30 49
-12 574 4 645 20 185 41 269 129 269 284 0 77 -18 128 -59 169 -34 34 -121 83
-148 83 -19 0 -16 20 5 27 44 16 95 60 120 102 23 40 27 57 27 126 0 137 -52
197 -205 236 -41 11 -97 20 -125 21 -476 9 -545 9 -551 -7z m506 -58 c14 -7
30 -28 37 -47 15 -46 16 -242 1 -292 -14 -49 -50 -78 -94 -78 l-34 0 0 208 c0
115 3 212 7 215 10 11 56 8 83 -6z m30 -506 c51 -36 61 -73 58 -216 -3 -119
-5 -133 -28 -168 -18 -26 -37 -41 -65 -49 -51 -16 -68 -16 -77 -3 -10 13 -10
449 -1 459 12 11 86 -4 113 -23z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
